{
  "notFound": "404 - Pô trouvé",
  "traducteur": "",
  "contact-us": "",
  "head": {
    "meta": {
      "description": "Ech'test y consiste in quêques questions (et tizotte t'eu les comptes pas) portint, pour el' plupart, sur ché thèmes ed' la piquette, del' sexe et d'ché drogues.",
      "title": "Test de pureté"
    }
  },
  "menu": {
    "test-purete": "Test de pureté",
    "random-shitpost": "random-shitpost.com"
  },
  "home-page": {
    "title": "test de pureté",
    "subtitle": "Ech'test y consiste in quêques questions (et tizotte t'eu les comptes pas) portint, pour el' plupart, sur ché thèmes ed' la piquette, del' sexe et d'ché drogues.",
    "intro-text": {
      "line-1": "Certaines ed' ché questions y pourraient choquer ché personnes les plus sinsibles.",
      "line-2": "Si tel est tin cas, t'eu va pas plus loin et t'eu r'vient in arrière ou t'eu ferme ech'fenêtre...",
      "line-3": "Sinon..."
    },
    "beware": "Surtout t'eu r'vient pas in arrière et... Bon courage !!",
    "ad-block": {
      "title": "⬇️Découvre not' nouveau site inutile⬇️"
    },
    "begin-griffor-btn": "👉 Test de pur'té classique",
    "begin-boudah-btn": "Verchion alternative",
    "continue-btn": "Reprendre l'test où chen étais !",
    "ad-warning": {
      "warning": "☝️ Attention, si une publicité s'ouvre, faites 'retour' ☝️",
      "our-test": "(notre test est et sera",
      "always": "toujours",
      "free": "gratuit) 😇"
    },
    "already": "D'jà ",
    "tests-made": " curieux ont fait ech'test ! 🎉"
  },
  "possibleAnswers": {
    "oui": "ouai",
    "non": "nan"
  },
  "questions": [
    {
      "id": "riMalheur",
      "question": "Tizotte t'as d'jà ri deul malheur ed' quéqu'un ?"
    },
    {
      "id": "riMental",
      "question": "Tizotte t'as d'jà ri d'ine personne mintalment ou physiqument hindicapée ?"
    },
    {
      "id": "poserLapin",
      "question": "Tizotte t'as d'jà posé un lapin à quéquin ?"
    },
    {
      "id": "foisArrete",
      "question": "Combien ed' fois t'eu t'ai d'jà été arrêté(e) ?"
    },
    {
      "id": "gouterAlcool",
      "question": "Tizotte t'as d'jà goûté à l'piquette ?"
    },
    {
      "id": "dejaIvre",
      "question": "Tizotte t'as d'jà eu t'prône ?"
    },
    {
      "id": "ivre48h",
      "question": "Tizotte t'as d'jà eu t'prône pindint plus d'48 heures ?"
    },
    {
      "id": "tomberIvre",
      "question": "Tizotte t'eu t'ai d'jà cassé t'giffe parce que t'avais t'prône ?"
    },
    {
      "id": "pisserDessus",
      "question": "Tizotte t'eu t'ai d'jà pissé d'sus quind t'avais t'prône ?"
    },
    {
      "id": "participerBoire",
      "question": "Tizotte t'as d'jà participé à d'ché jeux à chucher ?"
    },
    {
      "id": "buVomir",
      "question": "Tizotte t'as d'jà picollé assez pour dégobier ?"
    },
    {
      "id": "vomiQuelquun",
      "question": "Tizotte t'as d'jà dégobier sur ti ou sur quéqu'un d'aute ?"
    },
    {
      "id": "virerNuit",
      "question": "Tizotte t'eu t'ai d'jà fait déhutter d'un bistrot ou d'ine boîte ed'force ?"
    },
    {
      "id": "barathon",
      "question": "Tizotte t'as d'jà fait un barathon ?"
    },
    {
      "id": "alcoolDejeuner",
      "question": "Tizotte t'as d'jà eu un tchio déjeuner d'buveux ?"
    },
    {
      "id": "alcool3fois",
      "question": "Tizotte tu chuches souvint ? (au moins 3 fos din l'semaine) ?"
    },
    {
      "id": "jourPlus",
      "question": "Nombre de jours en plus des trois jours réguliers ?"
    },
    {
      "id": "dormiToilette",
      "question": "Tizotte t'as d'jàà dormi dans ed'ché toilettes ?"
    },
    {
      "id": "volontaireToilette",
      "question": "...T'étôt volontaire ?"
    },
    {
      "id": "evanouiBar",
      "question": "Tizotte t'eu t'ai d'jà indormi(e) ou évanoui(e) dins un bistrot ou ine boîte ?"
    },
    {
      "id": "avalerVomi",
      "question": "Tizotte t'as d'jà avalé tin vomi ou celui de quéquin d'autre ?"
    },
    {
      "id": "vomiLieuPublic",
      "question": "Tizotte t'as d'jà dégobier dans in lieu public ?"
    },
    {
      "id": "urinerPasPrevu",
      "question": "Tizotte t'as d'jà pissé dins un endroit qui n'étôt point prévu ) c't'usage (hors des cabinets) ?"
    },
    {
      "id": "essayerDrogue",
      "question": "Tizotte t'as déjà essayé ché drogues ?"
    },
    {
      "id": "drogueEssaye",
      "question": "Nombre ed' drogues :"
    },
    {
      "id": "defonce48h",
      "question": "Tizotte t'as d'jà été défoncé(e) pindint plus d'48 heures ?"
    },
    {
      "id": "drogueRegulier",
      "question": "Tizotte tu prends des drogues régulièrement ?"
    },
    {
      "id": "plus4fois",
      "question": "Plus ed' 4 fois par semaine ?"
    },
    {
      "id": "acheteDrogue",
      "question": "Tizotte t'as d'é'jà ach'té del'drogues?"
    },
    {
      "id": "venduDrogue",
      "question": "Tizotte t'as d'jà vindu del'drogues?"
    },
    {
      "id": "venduDrogueFinance",
      "question": "Tizotte t'as d'jà vindu del'drogues pour finincer ta ch'consommation"
    },
    {
      "id": "badTrip",
      "question": "Tizotte t'as d'jà eu un mauvais trip ?"
    },
    {
      "id": "initierDrogue",
      "question": "Tizotte t'as d'jà initié quéqu'un à ché drogues in général ?"
    },
    {
      "id": "profiterQuelquun",
      "question": "Tizotte t'as d'jà profité ed' quéqu'un pindint qu'il/elle avôt s'prône, qu'il/elle s'étot drogué(e), ou qu'il/elle étôt momintanément hindicapé(e) ?"
    },
    {
      "id": "sextape",
      "question": "Tizotte t'as d'jà fait une vidéo d'cul ?"
    },
    {
      "id": "rdvAmoureux",
      "question": "Tizotte t'as d'jà eu un rindez-vous amoureux ?"
    },
    {
      "id": "embrasseQuelquun",
      "question": "Tizotte t'as d'jà galoché et/ou tripoté quéqu'un ?"
    },
    {
      "id": "embrasserOrgasme",
      "question": "Tizotte t'as d'jà galoché et/ou tripoté quéqu'un jusqu'à ch'l'orgasme ?"
    },
    {
      "id": "relationsSexuelles",
      "question": "Tizotte t'as d'jà tiré tin coup ?"
    },
    {
      "id": "payerSexe",
      "question": "Tizotte t'as d'jà payé pour tirer tin coup ?"
    },
    {
      "id": "sexeArgent",
      "question": "Tizotte t'as d'jà tiré tin coup contre ed' l'argint ?"
    },
    {
      "id": "impliqueFellation",
      "question": "Tizotte t'as d'jà fait une fellation ou un cunnilingus ?"
    },
    {
      "id": "jusquaOrgasme",
      "question": "Jusqu'à ch'l'orgasme ?"
    },
    {
      "id": "rapportAnal",
      "question": "Tizotte t'as d'jà eu in rapport ed'l'anal ?"
    },
    {
      "id": "jusquaOrgasme2",
      "question": "Jusqu'à ch'l'orgasme ?"
    },
    {
      "id": "fait69",
      "question": "Tizotte t'as d'jà fait un \"69\" ?"
    },
    {
      "id": "ist",
      "question": "Tizotte t'as d'jà eu ine IST (infection sexuellement trinsmissible) ?"
    },
    {
      "id": "sansContraception",
      "question": "Tizotte t'as d'jà eu tiré tin coup sans utiliser ed' moyen ed' contraception ?"
    },
    {
      "id": "avortement",
      "question": "Tizotte t'as d'jà causé un avortemint ?"
    },
    {
      "id": "plusDunePersonne",
      "question": "Tizotte t'as d'jà eu tiré tin coup aveuc plus d'ine personne in même temps ?"
    },
    {
      "id": "plusDeuxPersonneSemaine",
      "question": "Tizotte t'as d'jà eu tiré tin coup aveuc plus ed' deusse personnes dins l'même semaine  ?"
    },
    {
      "id": "sexeLieuPublic",
      "question": "Tizotte t'as d'jà tiré tin coup dins un lieu public ?"
    },
    {
      "id": "blessureSexe",
      "question": "Tizotte t'as d'jà eu ed'ché brulures ed'tapis ou t'es d'jà blessé en tirant tin coup ?"
    },
    {
      "id": "homo",
      "question": "Tizotte t'as d'jà eu ed'ché rapports homosexuels (ou contraires à tin appartenance sexuelle) ?"
    },
    {
      "id": "bdsm",
      "question": "Tizotte t'as d'jà eu ed'ché expériences chado-magochistes ou pratiqué ch'bondage ?"
    },
    {
      "id": "sextoys",
      "question": "Tizotte t'as d'jà utilisé ed'ché sex toys ?"
    },
    {
      "id": "endormiSexe",
      "question": "Tizotte t'eu t'ai d'jà indormi(e) ou évanoui(e) pindint qu'tu tiré tin coup ?"
    },
    {
      "id": "perteVirginite",
      "question": "Tizotte t'as d'jà été responsable del' perte del' virginité ed' quéqu'un, et si ouai, ed' combien ed' personnes ?"
    },
    {
      "id": "acheterSexShop",
      "question": "Tizotte t'as d'jà acheté quéqu' chose dans in sex-shop ?"
    },
    {
      "id": "sexeFamilial",
      "question": "Tizotte t'as d'jà tiré tin coup avec un mimbre ed' ta famille ?"
    },
    {
      "id": "hesiter",
      "question": "T'as hésité avant ed'répondr à l'question préchédinte ?"
    },
    {
      "id": "zoophilieToussa",
      "question": "T'es attiré par la zoophilie, la nécrophilie ou ch'la pédophilie ?"
    },
    {
      "id": "coupSoir",
      "question": "Tizotte t'as d'jà eu in coup d'in soir ?"
    },
    {
      "id": "combienCoupSoir",
      "question": "Combien ed' fois ?"
    },
    {
      "id": "sexeSansAurevoir",
      "question": "Tizotte t'as d'jà eu in coup d'in soir, et t'es partie sans dire aurevoir ?"
    },
    {
      "id": "sexeMemeFamille",
      "question": "Tizotte t'as d'jà tiré tin coup avec plus d'ine personne deul' même famille ?"
    },
    {
      "id": "sexeDejaRelation",
      "question": "Tizotte t'as déjà tiré tin coup avec quéquin d'jà en couple ?"
    },
    {
      "id": "sexeMarie",
      "question": "Ed'ché personne étôt marié ?"
    },
    {
      "id": "sexeAmiPartenaire",
      "question": "Tizotte t'as d'jà tiré tin coup avec un(e) ami(e) de tin/ta partenaire offichiel(le) ?"
    },
    {
      "id": "sexeDeuxFoisVieux",
      "question": "Tizotte t'as d'jà tiré tin coup avec quéqu'un deusse fois plus vieux ?"
    },
    {
      "id": "bainMembreOppose",
      "question": "Tizotte t'as d'jà pris un bain ou ine douch avec un minbre d'un aut'sexe ?"
    },
    {
      "id": "lecherOeil",
      "question": "Tizotte t'as d'jà léché in oeil, in orteil, ou ine oreille ?"
    },
    {
      "id": "dejaMasturbation",
      "question": "Tizotte t'eu t'ai d'jà tripoté tin sexe ?"
    },
    {
      "id": "masturbationTelephone",
      "question": "Tizotte t'eu t'ai d'jà tripoté ech'sexe tout in étant à ch'téléphone ?"
    },
    {
      "id": "masturbationDeux",
      "question": "Tripotage mutuel ?"
    },
    {
      "id": "doucheDoree",
      "question": "Tizotte t'as d'jà partichipé à ine douche dorée (pisser sur quéqu'un d'aute ou s'faire pisser d'sus par quéqu'un d'aute) ?"
    },
    {
      "id": "reveErotique",
      "question": "Tizotte t'as d'jà fait un rêfe porchiot ?"
    },
    {
      "id": "voyeur",
      "question": "Tizotte t'as d'jà été raviseur(euse) ?"
    },
    {
      "id": "simulerOrgasme",
      "question": "Tizotte t'as d'jà simulé ch'l'orgasme ?"
    },
    {
      "id": "sortiSexe",
      "question": "Tizotte t'as d'jà sorti(e) avec quéqu'un juste pour tiret tin coup ?"
    },
    {
      "id": "sexePari",
      "question": "Tizotte t'as d'jà tiré tin coup pour gagner in pari ?"
    },
    {
      "id": "sexeCoupDeVent",
      "question": "Tizotte t'as d'jà tiré tin coup su ch' pouce (intre deusse cours, din ch' rue, et tout dins l'ugrince, sins préliminaires ou presque) ?"
    },
    {
      "id": "sexePromotion",
      "question": "Tizotte t'as d'jà tiré tin coup pour avoir ine promotion ou in travail ?"
    },
    {
      "id": "intimePublic",
      "question": "Tizotte t'as d'jà exhibé et' louloutte devint tout ch'monde ?"
    },
    {
      "id": "indelite",
      "question": "Tizotte t'as d'jà fait cocu tin partenaire ?"
    },
    {
      "id": "sexePartenaireAmi",
      "question": "Tizotte t'as d'jà tiré tin coup avec ch'partenaire d'un(e) ed'tes ami(e)s ?"
    },
    {
      "id": "sexeRegret",
      "question": "Tizotte t'as d'jà eu du sexe que tu r'grettes ?"
    },
    {
      "id": "telephoneSexe",
      "question": "Tizotte t'as d'jà téléphoné ou répondu ach'téléphone pindint qu'tu tirait tin coup ?"
    },
    {
      "id": "teleSexe",
      "question": "Tizotte t'as d'jà ravisé euch'télé in tirint tin coup ?"
    },
    {
      "id": "dessinAnimeSexe",
      "question": "Tizotte t'eu t'ai d'jà posé ed' ché questions sur eul' vie sexuelle ed' ché personnages ed' dessins-animés ?"
    },
    {
      "id": "ouSuisJe",
      "question": "Tizotte t'eu t'ai d'jà réveillé(e) in te d'mandant où t'êtôt ?"
    },
    {
      "id": "ouSuisJeAvecQui",
      "question": "Tizotte t'eu t'ai d'jà réveillé(e) in te d'mandant où t'êtôt et qui était ch'personne à côté d'toi' ?"
    },
    {
      "id": "tatouer",
      "question": "T'es tatoué ?"
    },
    {
      "id": "travaillerCaritative",
      "question": "Tizotte t'as d'jà fait travaillé pour ché oeuvres caritatifs ?"
    },
    {
      "id": "possedeBible",
      "question": "Tizotte t'eu possède ine bible ?"
    },
    {
      "id": "donCaritative",
      "question": "Tizotte t'as d'jà fait d'ché dons d'argint (pour ché oeuvres caritatifs) ?"
    },
    {
      "id": "lieuCulte",
      "question": "Tizotte t'as d'jà été ach'l'égliche hormis d'ché mariaches, d'ché communions, ou d'ché interr'mints ?"
    },
    {
      "id": "lieuCulteUneSemaine",
      "question": "...au moins ine fois par chemaine ?"
    },
    {
      "id": "ptitfilou",
      "question": "Tizotte t'as réalisé un d' ché actes ed' che test exprès pour améliorer tin score ?"
    }
  ],
  "results": {
    "global": {
      "you-are": "T'es",
      "your-score-is": "Ton score : ",
      "points": "point(s)",
      "reponses-positives": "Réponses positives",
      "moyenne-line": "Les scores peuvent aller de {minScore} à {maxScore}, la moyenne est de {moyenne} (sur {testsNumber} tests effectués)"
    },
    "purity-levels": {
      "tres-pur": "TRÈS PUR",
      "pur": "PUR",
      "plutot-pur": "PLUTÔT PUR",
      "impur": "IMPUR",
      "salement-impur": "SALEMENT IMPUR",
      "dangereux-impur": "DANGEREUX (ET IMPUR)",
      "ignoble-impur": "IGNOBLE (ET IMPUR)"
    },
    "category": {
      "sex": "Sexe",
      "alcohol": "Alcool",
      "drugs": "Drogue",
      "hygiene": "Hygiène",
      "moral": "Morale",
      "positives": "Positives"
    },
    "phrases": {
      "negatif": [
        "N'oubliez pas votre cabas pour faire votre marché après la messe, dimanche prochain...",
        "(Votre score est négatif : cela veut dire que Vous êtes une personne très pur(e) ou alors que Vous êtes trop jeune pour avoir fait ce test)"
      ],
      "10": [
        "Pour vous, les bonnes soeurs sont encore trop perverses !",
        "(Vous êtes considéré(e) comme une personne très pure ayant vécu peu d'expériences, peut-être par choix ou par manque de maturité ;-) )"
      ],
      "50": [
        "Arrêtez de survivre et essayez de vivre !",
        "(Fais de ta vie ce que tu veux en faire, le tout c'est de ne jamais rien regretter !)"
      ],
      "100": [
        "Ouais, vous utilisez votre main droite (ou gauche) pour vous branler, comme tout le monde !",
        "(Un score dans la moyenne, rien de bien transcendant, tout ça !)"
      ],
      "150": [
        "Juste supérieur à la moyenne. Tout ce qu'on peut vous dire, c'est que Vous êtes sorti(e) de chez vous quelque fois !",
        "(Un bon début ! Vous n'avez pas à rougir de qui que ce soit !)"
      ],
      "200": [
        "La vie n'est qu'un vaste terrain de jeu pour vous !",
        "(Votre passé a été plein de surprises et ce n'est pas fini, loin de là !)"
      ],
      "250": [
        "Vous vivez votre vie à fond sans peur du lendemain !",
        "(Un score qui vous donne le droit de clamer votre impureté à l'assistance :P)"
      ],
      "400": [
        "Ouais... On va essayer la camisole. Tout va bien, les messieurs en blancs s'occupent de tout.",
        "(Fais de ta vie ce que tu veux en faire, le tout c'est de ne jamais rien regretter !)"
      ],
      "max": [
        "Plus proche de l'animal que de l'homme (et encore), si vous pouviez vous lécher les parties génitales, vous ne sortiriez plus de chez vous",
        "(Bouh le gros dégueulasse !)"
      ]
    },
    "twitter": "J'ai fait {score} points au Test de Pureté #griffor ! Et toi ? :)"
  }
}
